
import {ContentfulClientApi, createClient, Entry, EntryCollection, LocaleCollection} from "contentful";
import {ContentModelName} from "./types";
import { AppMode } from "../http/protocol";

export default class ContentfulClientService {
    client : ContentfulClientApi = {} as ContentfulClientApi;
    public authorized: any;
    public currentSpace: any;

    constructor(space: string, accessToken: string, preview: boolean) {
        this.initClient(space, accessToken, preview);
    }

    private initClient = (space: string, accessToken: string, preview: boolean)  => {

        let environment:string|undefined;
        try {
            let override = localStorage.getItem("contentful_environment");
            if (override !== null) {
                console.log("Contentful env override", override);
                environment = override;
            }
    
        } catch (e) {
            console.error("Failed to get contentful environment from local storage (key: contentful_environment)", e);
        }
        if(!space || !accessToken) {
            return;
        }
        if (environment === undefined) {
            this.client = createClient({
                space: space,
                accessToken: accessToken,
                host: preview ? 'preview.contentful.com' : 'cdn.contentful.com'
            })
        } else {
            this.client = createClient({
                space: space,
                accessToken: accessToken,
                environment: environment,
                host: preview ? 'preview.contentful.com' : 'cdn.contentful.com'
            })
        }
    }

    public getEntriesForMultipleTypes(contentTypes: string[], locale?: string) {
        return this.client.getEntries({"sys.contentType.sys.id[in]": contentTypes.join(","), "locale": locale, include : 6})
    } 
    public getEntries(contentType: string, locale?: string, ids?: string[], limit?: number) {
        let q: any = {"content_type": contentType, "locale": locale, include : 6, "limit": (limit || 100)}
        if (ids) {
            q = {...q, "fields.id[in]": ids.join(",")}
        }
        return this.client.getEntries(q);
    }

    public getInfoPage(id : string, locale?: string) : Promise<EntryCollection<any>> {
        return this.client.getEntries({"fields.id" : id,"content_type": ContentModelName.INFOPAGE, "locale": locale, include : 6});
    }
    public getPromoPage(id : string, locale?: string) : Promise<EntryCollection<any>> {
        return this.client.getEntries({"fields.id" : id,"content_type": ContentModelName.PROMOPAGE, "locale": locale, include : 6});
    }

    public getGameListSection() : Promise<EntryCollection<any>> {
        return this.client.getEntries({"content_type": ContentModelName.GAMELISTSECTION});
    }

    public getGameListSectionById(id : string) : Promise<EntryCollection<any>> {
        return this.client.getEntries({"fields.id": id, "content_type": ContentModelName.GAMELISTSECTION});
    }

    public getUserLevelsPage(locale?: string) : Promise<EntryCollection<any>> {
        return this.client.getEntries({"content_type": ContentModelName.USERLEVELS, "locale": locale});
    }

    public getLocales() : Promise<LocaleCollection>{
        return this.client.getLocales();
    }

    public getContentType(id: string) {
        return this.client.getContentType(id);
    }

    public getClient = () => {
        return this.authorized && this.client;
    }

    public getCurrentSpaceName = (): string => {
        let currentSpaceName = (this.currentSpace && this.currentSpace.name) ? this.currentSpace.name : "";
        return currentSpaceName
    }

    public resetClient = () => {
        window.sessionStorage.clear()
        this.authorized = false
    }
}




// export { initClient, getClient, resetClient, getCurrentSpaceName }