import {SettingsUrl} from "./http/Types";
import axios from "axios";
import {Agent} from "@fingerprintjs/fingerprintjs";
import * as serviceWorker from "./serviceWorker";
import {SettingsService} from "./settings/SettingsService";
import {HttpService} from "./http/HttpService";
import {ACTIVE_LOCALE_COOKIE, addScript, addStylesheet, LocaleStatus} from "./bootstrap-utils";
import ContentfulClientService from "./contenful/ContentfulClientService";
import Cookies from "js-cookie";
import {getActiveLocale, getLocaleFromPath, setupI18n} from "./i18n";
import {Locale} from "./contenful/types";
import {Locale as ContentfulLocale} from "contentful";
import StrapiClient, {StrapiLocale} from "./contenful/StrapiClient";
import {Agent as AgentPro}  from '@fingerprintjs/fingerprintjs-pro';

if('serviceWorker' in navigator) {
    //starting up serivce worker and adding eventlistener for it.
    window.installedUsingNativePrompt = 'native';

    serviceWorker.register();

    window.addEventListener('beforeinstallprompt', (e) => {
        window.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', (evt) => {
        localStorage.setItem('appinstalled', 'true');
    });
}

declare global {
    interface Window {
        deferredPrompt: any,
        installedUsingNativePrompt:string
        PasswordCredential:any
        FederatedCredential:any
        PublicKeyCredential:any
        fcWidget:any
        fingerprint:Agent;
        fingerprintPro:AgentPro;
    }
}
type Config = { baseUrl : string };

/**
 * Basic loading process.
 *
 * Index.html
 *   A. load additional head links/preloads
 *   A. load initial js chunk
 *      B. load settings
 *          C. load locales -> load translations
 *          C. load css override
 *          C. load react root chunks/css
 */
(async function() {

    let config : Config | undefined = undefined;

    const domain = (window.location as any).hostname.replace(/^www\./, "");

    if (window.localStorage.getItem("config.json")) {
        config = JSON.parse(window.localStorage.getItem("config.json")!) ?? undefined
    } else if (domain == "localhost" || domain == "nano" || domain == "nano-2" || domain?.startsWith("127.0") || domain?.startsWith("10.0") || domain?.startsWith("192.168")) {
        try {
            config = await getConfig();
        } catch (e) {
            let hostname = document.location.hostname;
            if(hostname.startsWith("10.0") || hostname.startsWith("192.168"))  {
                config = { baseUrl : "http://" + hostname + ":8081/rest"};
            } else {
                config = { baseUrl : "http://localhost:8081/rest"};
            }
        }
    }
    if(!config) {
        config = { baseUrl : "https://" + domain + "/rest"};
    }
    let httpService = new HttpService(config.baseUrl);
    let settingsService = SettingsService.getInstance(httpService);
    let settings : any = undefined;
    try {
        settings = await settingsService.getSettings();
    } catch(e) {
        if(e.response?.status == 503 && (e.response?.headers?.["x-maintenance-reason"]  ?? "") == "scheduled" && !document.location.pathname.endsWith("/maintenance")) {
            document.location.pathname = "/maintenance";
        } else if(document.location.pathname == "/maintenance") {
            import("./maintenance-on-load").then((r) => {
                r.maintenanceOnLoad(httpService);
            })
        }
        return;
    }



    let fonts = false;
    if(settings && settings.customScripts && settings.customScripts.length > 0) {
        try {
            let scripts = settings.customScripts.split("\n");

            if(scripts.length > 0) {
                scripts.forEach((url : string) => {
                    url = url.trim();
                    if(url.startsWith("font:")){
                        fonts = true;
                        addStylesheet(url.replace("font:","").trim());
                    } else if(url.startsWith("css:")) {
                        addStylesheet(url.replace("css:","").trim());
                    } else {
                        if(url.startsWith("script:")) {
                            url = url.replace("script:", "");
                        }
                        addScript(url);
                    }

                })
            }
            //include default fonts

        } catch (e) {
            console.log("failed to load scripts from settings: ", e);
        }
    }

    //default fonts
    if(fonts == false) {
        addStylesheet("https://fonts.googleapis.com/css2?family=Asap:wght@400;500;600&display=swap")
        addStylesheet("https://fonts.googleapis.com/css?family=Rubik");
        addStylesheet("https://fonts.googleapis.com/css?family=Quantico&display=swap");
    }
        
    
    let spaceId = settings.contenfulSpaceId ? settings.contenfulSpaceId : "";
    let accessToken = settings.contentfulAccessToken ? settings.contentfulAccessToken : "";
    let contentfulClientService = new ContentfulClientService(spaceId, accessToken, false);
    let strapiClient = new StrapiClient(settings.strapiApiUrl)
    
    let promises = Promise.all([
        settings?.cssOverrideUrl ? fetchCssOverride(settings.cssOverrideUrl) : Promise.resolve(""),
        setupLocales(config.baseUrl, settingsService, contentfulClientService, strapiClient),
        settings?.menu && settings?.menu?.settings && settings?.menu?.settings?.iconPack ? fetchCssOverride(settings?.menu?.settings?.iconPack) :  Promise.resolve(""), 
    ]);
    
    
    
    console.log("load application")
    import("./load").then( fn => {
        console.log("application starting v2");
        fn.startApplication(config, settings, settingsService, httpService, domain, contentfulClientService,  strapiClient, promises as any);
    })
})();

async function setupLocales(
        baseUrl : string,
        settingsService : SettingsService,
        contentfulClientService : ContentfulClientService,
        strapiClient : StrapiClient) : Promise<LocaleStatus> {

    let localeFromPath = getLocaleFromPath();

    let cookieLocale = Cookies.get(ACTIVE_LOCALE_COOKIE);

    let activeLocale;
    if(strapiClient.isEnabled()) {
        let localesList : StrapiLocale[] = await strapiClient.fetchLocales();

        if(localesList && localesList.length > 1) {
            let locales: Array<Locale> = [];
            localesList.map((item: StrapiLocale) => locales.push({code: item.code, name: item.name, defaultLocale : item.isDefault, fallback : "en"}));
            settingsService.setLocalesData(locales);
        }
        activeLocale = getActiveLocale(settingsService, localeFromPath, cookieLocale);
    } else {
        let localesList = await contentfulClientService.getLocales();
        if(localesList && localesList.items && localesList.items.length > 1) {
            let locales: Array<Locale> = [];
            localesList.items.map((item: ContentfulLocale) => locales.push({code: item.code, name: item.name, defaultLocale : item.default, fallback : item.fallbackCode}));
            settingsService.setLocalesData(locales);
        }
        activeLocale = getActiveLocale(settingsService, localeFromPath, cookieLocale);
    }


    await setupI18n(baseUrl, activeLocale.code, settingsService);
    return {
        activeLocale : activeLocale,
        localeFromPath : localeFromPath,

    };
}
async function getConfig() : Promise<SettingsUrl> {
    let http =axios.create({
        headers : {
            "Content-type" : "application/json"
        }
    });
    return http.get("/config/config.json").then( r => r.data);
}

export function fetchCssOverride(cssOverrideUrl : string) {

    // Allowing for local dev CSS override
    try {
        let devCssOverride = localStorage.getItem("css_override");
        if (devCssOverride !== null) {
            console.log("Using local dev CSS override", devCssOverride);
            cssOverrideUrl = devCssOverride;
        }
    } catch (e) {
        console.error("Failed to get local dev CSS override from local storage (key: css_override)", e);
    }
    console.log("cssOverrideUrl = ", cssOverrideUrl);
    return new Promise<string>((resolve, reject) => {
        let link    = document.createElement('link');
        link.type   = 'text/css';
        link.rel    = 'stylesheet';
        link.onload = () => {
            resolve(cssOverrideUrl);
        };
        link.onerror = (e) => {
            console.error("unable to load css override", e);
            reject("error loading css");
        };
        link.href   = cssOverrideUrl;
        let body = document.querySelector('body');
        body && body.appendChild(link);
    });
}
