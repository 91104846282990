/* tslint:disable */
/* eslint-disable */

export interface AccountBalance {
    balance?: Money;
    reservedBonus?: Money;
    role?: AccountRole;
}

export interface AppIntegrationLoginRequest extends LoginRequest {
    internalUserAttributeStoredOnUser?: InternalUserAttributes;
    tradable?: string;
}

export interface AuthToken {
    device?: string;
    id?: number;
}

export interface BalanceAndBonus {
    accountBalanceList?: AccountBalance[];
    activeBonusList?: UserBonusView[];
    activeOfferList?: BonusOffer[];
    currencyDefinitionList?: CurrencyDefinition[];
    messages?: ListUserMessageResponse;
}

export interface BalanceAndBonusRequest {
    currencies?: string[];
    skipBalance?: boolean;
}

export interface BankInfo {
    accountAddress1?: string;
    accountAddress2?: string;
    accountCity?: string;
    accountCountry?: string;
    accountNumber?: string;
    accountOwner?: string;
    accountState?: string;
    accountZip?: string;
    name?: string;
    routingNumber?: string;
}

export interface BankTransferContext {
    bankAccountNumber?: string;
    bankAccountOwner?: string;
    bankName?: string;
    bankRoutingNumber?: string;
}

export interface BonusConfig {
    blockUponOtherClaims?: boolean;
    bonusAlertDescription?: string;
    bonusStatusAlert?: BonusStatusAlert;
    description?: string;
    expiresAfterDays?: number;
    hasOfferedBonus?: boolean;
    id?: number;
    imageUrl?: string;
    monetaryConfig?: MonetaryConfig;
    name?: string;
    status?: BonusStatus;
    summary?: string;
    translations?: BonusConfigTranslation[];
    trigger?: BonusTrigger;
    turnoverContributions?: TurnoverContributions;
    type?: BonusLogicType;
    validFromDate?: Date;
    validToDate?: Date;
}

export interface BonusConfigTranslation {
    description?: string;
    id?: number;
    imageUrl?: string;
    language?: string;
    name?: string;
    summary?: string;
}

export interface BonusContext {
    blockedFromPlaying?: boolean;
    contributionFactor?: number;
    maxWager?: number;
}

export interface BonusOffer {
    amount?: number;
    bonusConfig?: BonusConfig;
    currency?: string;
    expires?: Date;
    id?: number;
    inputAmount?: number;
    status?: BonusOfferStatus;
    timestamp?: Date;
    turnoverRequirement?: number;
    userId?: number;
}

export interface BonusSportsbookInfo {
    contributions?: Contribution[];
    forbidden?: boolean;
    restricted?: boolean;
}

export interface BonusTrigger {
    count?: number;
    event?: string;
    expiresAfterDays?: number;
    id?: number;
    name?: string;
    triggerType?: RuleTriggerType;
}

export interface BonusUpcomingOffer {
    award?: boolean;
    bonusConfig?: BonusConfig;
    expiry?: Date;
}

export interface Bundle {
    awardFunds1?: Monetary;
    awardFunds2?: Monetary;
    awardPokerItemId?: string;
    awardPokerItemList?: PokerItem[];
    created?: Date;
    description?: string;
    enabled?: boolean;
    featured?: boolean;
    id?: number;
    imageUrl?: string;
    label?: string;
    name?: string;
    nameId?: string;
    price?: Monetary;
    type?: BundleType;
}

export interface BuyBundleRequest {
    method?: string;
    provider?: string;
}

export interface CasinoCurrencies {
    currencies?: string[];
    integration?: GameIntegration;
    studio?: number;
    studioId?: number;
}

export interface CasinoGameInfo {
    allowedCountries?: string;
    aspectRatio?: CasinoGameAspectRatio;
    backgroundImageUrl?: string;
    blockedCountries?: string;
    description?: string;
    desktop?: boolean;
    display?: GameDisplay;
    externalId?: string;
    gameTags?: number[];
    gameType?: CasinoGameType;
    id?: number;
    imageUrl?: string;
    imageVariants?: string[];
    integration?: GameIntegration;
    jurisdictions?: number[];
    mobile?: boolean;
    mobileExternalId?: string;
    mobileNavigation?: CasinoMobileNavigation;
    name?: string;
    playForFun?: boolean;
    promoImageUrl?: string;
    releaseDate?: Date;
    studioId?: number;
    translations?: CasinoGameTranslation[];
}

export interface CasinoGameTranslation {
    description?: string;
    id?: number;
    imageUrl?: string;
    language?: string;
    name?: string;
}

export interface CasinoLobby {
    currencies?: CasinoCurrencies[];
    games?: CasinoGameInfo[];
    images?: CentralImagesConfig;
    jurisdictions?: Jurisdiction[];
    pages?: CasinoPage[];
    studios?: CasinoStudio[];
    tags?: GameTag[];
}

export interface CasinoLobbyCategory {
    alwaysRenderVertically?: boolean;
    categoryType?: CasinoLobbyCategoryType;
    contentKey?: string;
    gameOrder?: GameOrder;
    gameTypes?: CasinoGameType[];
    games?: number[];
    id?: number;
    imgVariant?: Style;
    itemView?: number;
    lobbyOrder?: number;
    matchAllSelectedTags?: boolean;
    maxNrOfGames?: number;
    name?: string;
    studioIds?: number[];
    studios?: number[];
    surpriseEnabled?: boolean;
    tags?: number[];
    translationKey?: string;
    translations?: { [index: string]: string };
}

export interface CasinoPage {
    defaultPage?: boolean;
    gameTags?: number[];
    games?: number[];
    hideCountryRestrictedGames?: boolean;
    id?: number;
    name?: string;
    singleTab?: CasinoPageTab;
    studios?: number[];
    tabs?: CasinoPageTab[];
}

export interface CasinoPageTab {
    categories?: CasinoLobbyCategory[];
    id?: number;
    name?: string;
    translations?: { [index: string]: string };
}

export interface CasinoSortedLobby extends Serializable {
    currencies?: CasinoCurrencies[];
    games?: { [index: string]: CasinoGameInfo };
    images?: CentralImagesConfig;
    jurisdictions?: Jurisdiction[];
    pages?: CasinoPage[];
    singlePage?: CasinoPage;
    studios?: { [index: string]: CasinoStudio };
    tags?: { [index: string]: GameTag };
}

export interface CasinoStudio {
    allowedCountries?: string;
    blockedCountries?: string;
    blockedCurrencies?: string;
    enabled?: boolean;
    externalId?: string;
    gameTagsToBeAdded?: string;
    gameTagsToBeRemoved?: string;
    icon?: string;
    id?: number;
    imageUrl?: string;
    integration?: GameIntegration;
    lobbyOrder?: number;
    mobileNavigation?: boolean;
    name?: string;
    popular?: boolean;
    promoImageUrl?: string;
    providerBlockedCountries?: string;
}

export interface CentralImagesConfig {
    enabled?: boolean;
    game?: Games;
    integrations?: Integrations;
    provider?: Provider;
}

export interface ClientApplicationConfig {
    clientBaseUrl?: string;
    facebookAppId?: string;
    googleAnalyticsId?: string;
    googleTagManagerId?: string;
    manifest?: string;
    operatorId?: string;
}

export interface Config {
    /**
     * The parameter defines the gap between window bottom and betslip (for example, fixed footer).
     */
    betSlipOffsetBottom?: number;
    /**
     * The parameter defines the gap between window bottom and betslip (for example, fixed footer).
     */
    betSlipOffsetBottomMobile?: number;
    /**
     * The parameter defines the gap between window right side and betslip (for example, fixed sidebar).
     */
    betSlipOffsetRight?: number;
    /**
     * The parameter defines the gap between window right side and betslip (for example, fixed sidebar).
     */
    betSlipOffsetRightMobile?: number;
    /**
     * The parameter defines the gap between window top and opened betslip (for example, fixed header).
     */
    betSlipOffsetTop?: number;
    /**
     * The parameter defines the gap between window top and opened betslip (for example, fixed header).
     */
    betSlipOffsetTopMobile?: number;
    /**
     * The parameter defines the index of betslip in relation to DOM elements used on Partners website. It helps to avoid overlapping conflicts of different elements. Value should be 100 or more.
     */
    betslipZIndex?: number;
    /**
     * The parameter defines the index of betslip in relation to DOM elements used on Partners website. It helps to avoid overlapping conflicts of different elements. Value should be 100 or more.
     */
    betslipZIndexMobile?: number;
    /**
     * Operator brand id
     */
    brand_id?: string;
    /**
     * Default currency
     */
    currency?: string;
    /**
     * The height value of fixed header
     */
    stickyTop?: number;
    /**
     * The height value of fixed header
     */
    stickyTopMobile?: number;
    /**
     * Theme name for custom css override
     */
    themeName?: string;
}

export interface Contribution {
    contributionFactor?: number;
    description?: string;
    excludeCasinoTags?: string;
    id?: number;
    includeCasinoTags?: string;
    liveBetting?: boolean;
    maxOdds?: number;
    minOdds?: number;
    preMatchBetting?: boolean;
    product?: ContributionProduct;
    translations?: ContributionTranslation[];
}

export interface ContributionTranslation {
    description?: string;
    id?: number;
    language?: string;
}

export interface Country {
    alpha2?: string;
    alpha3?: string;
    callingCode?: number;
    translations?: { [index: string]: string };
}

export interface CountryInfo {
    blocked?: boolean;
    countryCode?: string;
    countryName?: string;
    jurisdiction?: Jurisdiction;
}

export interface CryptoExchangeRate {
    currencyDefinition?: CurrencyDefinition;
    rate?: number;
}

export interface CryptoWithdrawContext {
    coin?: string;
    coinName?: string;
    fees?: CryptoWithdrawFees;
    network?: string;
    networkName?: string;
    token?: string;
}

export interface CryptoWithdrawFees {
    currency?: string;
    priority?: NetworkPriority;
    withdrawFee?: number;
}

export interface Currency extends Serializable {
    code?: string;
    cryptoCurrency?: boolean;
    cryptoExchangeEnabled?: boolean;
    eurExchangeRate?: number;
    fractionalDigits?: number;
    irlCurrencyCode?: string;
    irlCurrencyFactor?: number;
    name?: string;
    postfixSymbol?: string;
    prefixSymbol?: string;
    visible?: boolean;
}

export interface CurrencyDefinition {
    code?: string;
    cryptoCurrency?: boolean;
    cryptoPrices?: { [index: string]: CryptoExchangeRate };
    fractionalDigits?: number;
    irlCurrencyCode?: string;
    irlCurrencyFactor?: number;
    name?: string;
    postfixSymbol?: string;
    prefixSymbol?: string;
    visible?: boolean;
}

export interface CustomerInfo {
    address?: string;
    bank?: BankInfo;
    context?: any;
    countryCode?: string;
    dateOfBirth?: Date;
    email?: string;
    firstName?: string;
    ipAddress?: string;
    languageCode?: string;
    lastName?: string;
    mobileNumber?: string;
    nickname?: string;
    paymentPermitted?: PaymentPermitted;
    personId?: string;
    phone?: string;
    state?: string;
    userId?: number;
    userUid?: string;
}

export interface DepositContext {
    defaultDepositAmount?: number;
    kycRequired?: boolean;
    maxDeposit?: number;
    minDeposit?: number;
    providerProperties?: { [index: string]: string };
}

export interface DepositResult {
    message?: string;
    paymentId?: number;
    paymentUid?: string;
    referenceId?: string;
    result?: DepositResultCode;
}

export interface Exchange {
    local?: ExchangeCurrency;
    provider?: ExchangeCurrency;
}

export interface ExchangeCurrency {
    code?: string;
    name?: string;
}

export interface FacebookLoginRequest extends LoginRequest {
    facebookToken?: string;
}

export interface FormStep {
    groups?: UserInputGroup[];
}

/**
 * Freshchat Configuration
 */
export interface FreshchatConfig {
    headerProperty?: HeaderProperty;
}

export interface GLifeLoginRequest extends AppIntegrationLoginRequest {
    authCode?: string;
}

export interface GameTag {
    display?: boolean;
    id?: number;
    name?: string;
    nameId?: string;
    translations?: { [index: string]: string };
}

export interface Games {
    styles?: { [P in Style]?: string };
}

export interface HeaderProperty {
    /**
     * Logo Url
     */
    appLogo?: string;
    /**
     * Application Name
     */
    appName?: string;
    /**
     * Background Color in CSS syntax: #123456
     */
    backgroundColor?: string;
    /**
     * Background Image Url
     */
    backgroundImage?: string;
    /**
     * Foreground Color in CSS syntax: #123456
     */
    foregroundColor?: string;
}

export interface Integrations {
    completed?: string[];
    studios?: { [index: string]: string[] };
}

export interface Jackpot {
    externalId?: string;
    games?: JackpotGame[];
    integration?: GameIntegration;
    name?: string;
    pools?: JackpotPool[];
}

export interface JackpotBalance {
    amount?: number;
    currencyCode?: string;
}

export interface JackpotGame {
    externalId?: string;
    id?: number;
    name?: string;
}

export interface JackpotList {
    jackpots?: Jackpot[];
}

export interface JackpotPool {
    pool?: JackpotBalance[];
    type?: string;
}

export interface Jurisdiction {
    allowedCountries?: string[];
    blockedCountries?: string[];
    id?: number;
    name?: JurisdictionName;
}

export interface LaunchBetbyResponse {
    config?: Config;
    proxyUrl?: string;
    token?: string;
}

export interface LaunchCasinoGameResponse {
    bonusContext?: BonusContext;
    countryBlock?: boolean;
    currencyNotSupported?: boolean;
    exchange?: Exchange;
    kycRequired?: boolean;
    originals?: boolean;
    url?: string;
}

export interface LaunchUrl {
    blockedDueToActiveBonus?: boolean;
    token?: string;
    url?: string;
}

export interface ListBundlesResponse extends ListResponse {
    items?: Bundle[];
}

export interface ListRequest extends UnorderedListRequest {
    ascending?: boolean;
    orderBy?: string;
}

export interface ListResponse {
    nrOfResults?: number;
}

export interface ListUserGameRewardRequest extends ListRequest {
    excludeExpired?: boolean;
    id?: number;
    removed?: boolean;
    status?: RewardStatus;
    userId?: number;
}

export interface ListUserMessageRequest extends ListRequest {
    userId?: number;
}

export interface ListUserMessageResponse extends ListResponse {
    messages?: UserMessage[];
    unreadMessages?: number;
}

export interface LoginRequest {
    appMode?: AppMode;
    device?: string;
    deviceId?: string;
    generateLoginToken?: boolean;
    mfaProvider?: MultiFactorAuthProvider;
    mfaVerificationCode?: number;
    termsVersionToAccept?: string;
    visitorId?: string;
}

export interface MaintenanceAndServerTime {
    maintenanceWindow?: MaintenanceWindow;
    productMaintenance?: MaintenanceWindow[];
    serverTime?: Date;
}

export interface MaintenanceWindow {
    announceBeforeStart?: Date;
    category?: MaintenanceCategory;
    enabled?: boolean;
    id?: number;
    start?: Date;
}

export interface ManualLoginRequest extends LoginRequest {
    email?: string;
    password?: string;
    phoneNumber?: string;
    verification?: LoginVerification;
}

export interface MayaMiniAppLoginRequest extends AppIntegrationLoginRequest {
    sessionId?: string;
}

export interface Menu {
    footerItems?: MenuItem[];
    items?: MenuItem[];
    settings?: MenuSettings;
}

export interface MenuComponent {
    loggedInComponent?: MenuComponentType;
    loggedOutComponent?: MenuComponentType;
}

export interface MenuItem {
    disableSidebarCollapse?: boolean;
    excludeCountries?: string;
    expandSubmenuOnly?: boolean;
    icon?: string;
    includeCountries?: string;
    includeInDesktopMenu?: boolean;
    includeInMobileMenu?: boolean;
    includeInSidebarMenu?: boolean;
    name?: string;
    subMenu?: SubMenu;
    supportLink?: boolean;
    target?: string;
    translations?: { [index: string]: string };
    type?: MenuItemType;
    url?: string;
}

export interface MenuSettings {
    enabeLogoSideMenu?: boolean;
    enableLogoTopMenu?: boolean;
    iconPack?: string;
    menuComponents?: MenuComponent;
    partialCollapsed?: boolean;
    position?: MenuPosition;
}

export interface Monetary {
    amount?: number;
    currency?: string;
}

export interface MonetaryConfig {
    awardAmountFraction?: number;
    awardType?: AwardType;
    bonusPaymentType?: BonusPaymentType;
    bustThreshold?: number;
    cashRewardTemplateId?: number;
    currencyCode?: string;
    id?: number;
    inputAmountAsBonusMoney?: boolean;
    maxInputAmount?: number;
    maxWagerAmount?: number;
    name?: string;
    removed?: boolean;
    turnoverRequirementFactor?: number;
}

export interface Money extends Serializable {
    amount?: number;
    currencyCode?: string;
    fractionalDigits?: number;
}

export interface MultiFactorAuthRequest {
    provider?: MultiFactorAuthProvider;
    verificationCode?: number;
}

export interface MultiFactorAuthResponse {
    imageUrl?: string;
    issuer?: string;
    provider?: MultiFactorAuthProvider;
    secret?: string;
    timeToLiveSeconds?: number;
    username?: string;
}

export interface NetworkPriority {
    high?: number;
    low?: number;
    medium?: number;
}

export interface NextUpcomingCashback {
    amount?: number;
    calculationTime?: Date;
    configId?: number;
    configName?: string;
    currency?: string;
    frequency?: string;
    notEligibleReason?: NotEligibleReason;
    payoutOngoing?: boolean;
    scheduled?: Date;
}

export interface OneTimeTokenResponse {
    token?: string;
}

export interface OnramperLaunchResponse {
    openInNewWindow?: boolean;
    url?: string;
}

export interface Order {
    amount?: number;
    currencyCode?: string;
    custom?: any;
    customerInfo?: CustomerInfo;
    destinationTag?: string;
    failUrl?: string;
    method?: string;
    notes?: string;
    priority?: Priority;
    purchaseBundleId?: number;
    purchaseBundleName?: string;
    recipientAddress?: string;
    requestKyc?: boolean;
    reuseAddressIfPossible?: boolean;
    successUrl?: string;
    type?: PaymentType;
    uid?: string;
}

export interface Payment {
    address?: string;
    amount?: number;
    amountEuro?: number;
    attributes?: { [index: string]: string };
    created?: Date;
    cryptoTxReference?: string;
    currency?: string;
    destinationTag?: string;
    externalPaymentId?: string;
    finalized?: boolean;
    id?: number;
    log?: PaymentLog[];
    order?: Order;
    paymentReverseTxId?: number;
    paymentTxId?: number;
    provider?: string;
    status?: PaymentStatus;
    type?: PaymentType;
    uid?: string;
    updated?: Date;
    userId?: number;
}

export interface PaymentDialog {
    customContext?: any;
    iframe?: string;
    iframeUrl?: string;
    openInNewWindow?: boolean;
    payment?: Payment;
    uid?: string;
}

export interface PaymentInput {
    defaultValue?: string;
    name?: string;
    options?: string;
    required?: boolean;
    type?: PaymentInputType;
}

export interface PaymentLog {
    data?: string;
    error?: boolean;
    id?: number;
    message?: string;
    paymentId?: number;
    referenceId?: string;
    status?: PaymentStatus;
    timestamp?: Date;
    type?: PaymentLogEventType;
}

export interface PaymentMethod {
    achBankTransfer?: boolean;
    addressRequired?: boolean;
    amountRequired?: boolean;
    attributes?: { [index: string]: string };
    canReuseAddress?: boolean;
    code?: string;
    depositEnabled?: boolean;
    destinationTag?: boolean;
    group?: string;
    instantDepositEnabled?: boolean;
    name?: string;
    provider?: string;
    recommendedWalletURL?: string;
    requireKycOnDeposit?: boolean;
    requireKycOnWithdraw?: boolean;
    showNetworkFee?: boolean;
    withdrawEnabled?: boolean;
    zeroNetworkFee?: boolean;
}

export interface PlayerOrder {
    amount?: number;
    currencyCode?: string;
    custom?: any;
    destinationTag?: string;
    method?: string;
    notes?: string;
    priority?: Priority;
    purchaseBundleId?: number;
    purchaseBundleName?: string;
    recipientAddress?: string;
    reuseAddressIfPossible?: boolean;
    type?: PaymentType;
}

export interface PlayerToPlayerTransferRequest {
    amount?: number;
    currency?: string;
    toUserUid?: string;
}

export interface PlayerTransaction {
    amount?: Money;
    attributes?: { [index: string]: string };
    id?: number;
    timestamp?: Date;
}

export interface PlayerTransactionsRequest {
    currency?: string;
    limit?: number;
    offset?: number;
    type?: Product;
}

export interface PlayerTransactionsResponse {
    transactions?: PlayerTransaction[];
}

export interface PokerItem {
    allowMultiple?: boolean;
    category?: string;
    description?: string;
    id?: string;
    imageUrl?: string;
    isEquippable?: boolean;
    name?: string;
    nameId?: string;
    operatorId?: number;
    value?: number;
    voucherCurrencyCode?: string;
    voucherValue?: number;
}

export interface PokerPlayerCount {
    online?: number;
    seated?: number;
}

export interface Provider {
    styles?: { [P in Style]?: string };
}

export interface ProviderRewardResponse {
    errorCode?: number;
    message?: string;
    referenceCode?: string;
}

export interface PushSubscription {
    auth?: string;
    endpoint?: string;
    expirationTime?: number;
    key?: string;
}

export interface ReCaptchaFrontendSettings {
    actions?: ReCaptchaAction[];
    enabled?: boolean;
    siteKey?: string;
}

export interface RegistrationRequest {
    attributes?: { [index: string]: string };
    country?: string;
    currency?: string;
    deviceId?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    principal?: UserLoginPrincipal;
    repeatPassword?: string;
    username?: string;
}

export interface RegistrationResponse {
    field?: string;
    status?: Status;
    userInfo?: UserInfo;
}

export interface RequestPrincipalVerification {
    principal?: string;
    verification?: LoginVerification;
}

export interface RequestWithdrawResponse {
    accepted?: boolean;
    paymentId?: number;
    paymentUid?: string;
    referenceId?: string;
    result?: PaymentResultCode;
}

export interface ResetPasswordRequest {
    address?: string;
    newPassword?: string;
    principal?: UserLoginPrincipal;
    resetPasswordCode?: string;
}

export interface RewardTemplate {
    created?: Date;
    currencyCode?: string;
    defaultAmount?: number;
    defaultCasinoGameId?: number;
    defaultNrOfFreeSpins?: number;
    description?: string;
    descriptionKey?: string;
    expireAfterDays?: number;
    iconUrl?: string;
    id?: number;
    imageUrl?: string;
    name?: string;
    product?: Product;
    rewardType?: RewardType;
    templateId?: string;
    titleKey?: string;
    translations?: RewardTemplateTranslation[];
    validForUserUntilDays?: number;
}

export interface RewardTemplateTranslation {
    id?: number;
    imageUrl?: string;
    language?: string;
}

export interface ScriptWidget {
    scriptUrl?: string;
    variableName?: string;
}

export interface Serializable {
}

export interface Settings {
    acquisitionScript?: string;
    amplitudeApiKey?: string;
    appInstallButtonsEnabled?: boolean;
    appNativePromptEnabled?: boolean;
    appPopupEnabled?: boolean;
    bonusEnabled?: boolean;
    bundleBonusCurrency?: string;
    canonicalBaseUrl?: string;
    casinoEnabled?: boolean;
    clientManifest?: string;
    contenfulSpaceId?: string;
    contentfulAccessToken?: string;
    countryBlockEnabled?: boolean;
    cssOverrideUrl?: string;
    currencies?: CurrencyDefinition[];
    currencyOrder?: string;
    customScripts?: string;
    defaultFiatExchangeCurrency?: string;
    delayAppInstallPopup?: number;
    displayFiatExchangeByDefault?: boolean;
    emailSignupAvailable?: boolean;
    facebookAppId?: string;
    faqPageEnabled?: boolean;
    fingerprintDomain?: string;
    fingerprintEnabled?: boolean;
    fingerprintPublicKey?: string;
    forceWithdrawCurrency?: string;
    freshChatConfig?: FreshchatConfig;
    freshChatEnabled?: boolean;
    freshChatHost?: string;
    freshChatWidgetSiteId?: string;
    freshChatWidgetToken?: string;
    googleAnalyticsId?: string;
    id?: number;
    iosMediaJSON?: string;
    kycOnDepositRequired?: boolean;
    kycOnPlayRequired?: boolean;
    kycOnWithdrawRequired?: boolean;
    languageOrder?: string;
    loginRedirectUrl?: string;
    loginRequestCoolDown?: number;
    loginRequestInterval?: number;
    loginThrottleEnabled?: boolean;
    loginsRequiredForPushPrompt?: number;
    matiClientId?: string;
    matiEnabled?: boolean;
    maxLoginCount?: number;
    maxVerificationAttempts?: number;
    menu?: Menu;
    mfaEnabled?: boolean;
    minimumAge?: number;
    noOfTimeBeforeHidingPrompt?: number;
    noOfTimeBeforePromptingAgain?: number;
    onramperCurrencies?: string;
    operatorIconUrl?: string;
    operatorName?: string;
    phoneSignupAvailable?: boolean;
    pokerEnabled?: boolean;
    pushNotificationsEnabled?: boolean;
    pwaEnabled?: boolean;
    pwaEnabledAndroid?: boolean;
    pwaEnabledIOS?: boolean;
    pwaEnabledWindows?: boolean;
    racingEnabled?: boolean;
    reCaptchaSettings?: ReCaptchaFrontendSettings;
    requireUserChannelVerification?: boolean;
    requiredUserAttributes?: UserAttributeDefinition[];
    showCurrencySelectOnSignup?: boolean;
    showUserLevel?: boolean;
    skipDepositPageOnRegister?: boolean;
    sportsbookConfig?: SportsbookConfig;
    sportsbookEnabled?: boolean;
    sportsbookProvider?: SportsbookProvider;
    startPokerOnOpen?: boolean;
    strapiApiUrl?: string;
    termsVersion?: string;
    tippingEnabled?: boolean;
    title?: string;
    tokenLoginEnabled?: boolean;
    transferLimits?: TransferLimit[];
    useAmplitude?: boolean;
    useInfoTextSignup?: boolean;
    useStoreInsteadOfDeposit?: boolean;
    vouchersEnabled?: boolean;
    widgets?: ScriptWidget[];
}

export interface SiteMap {
    siteMapUrls?: SiteMapUrl[];
}

export interface SiteMapUrl {
    location?: string;
    xhtmlLinks?: XhtmlLink[];
}

export interface SportsbookConfig {
    enabledConfig?: boolean;
    providers?: SportsbookProviderOption[];
}

export interface SportsbookProviderOption {
    pathname?: string;
    provider?: SportsbookProvider;
}

export interface SubMenu {
    defaultCasinoPage?: boolean;
    icon?: string;
    id?: number;
    name?: string;
    tabs?: SubMenuTab[];
}

export interface SubMenuTab {
    icon?: string;
    id?: number;
    name?: string;
    target?: string;
    translations?: { [index: string]: string };
    type?: MenuItemType;
    url?: string;
}

export interface TokenLoginRequest extends LoginRequest {
    secret?: string;
    token?: string;
}

export interface TransferLimit {
    currency?: string;
    defaultDepositAmount?: number;
    maxDepositAmount?: number;
    maxWithdrawAmount?: number;
    minDepositAmount?: number;
    minTipAmount?: number;
    minWithdrawAmount?: number;
}

export interface TurnoverContributions {
    contributions?: Contribution[];
    id?: number;
    name?: string;
}

export interface UnorderedListRequest {
    limit?: number;
    offset?: number;
}

export interface UserAttributeDefinition {
    attributeKey?: string;
    descriptionTranslationKey?: string;
    id?: number;
    maxLength?: number;
    minLength?: number;
    nameTranslationKey?: string;
    options?: string;
    ordinal?: number;
    regex?: string;
    required?: boolean;
    type?: AttributeType;
    userWriteAccess?: UserWriteAccess;
}

export interface UserBonusListResponse extends ListResponse {
    items?: UserBonusView[];
}

export interface UserBonusView {
    bonusConfig?: BonusConfig;
    currency?: string;
    currentTurnover?: number;
    expires?: Date;
    id?: number;
    parachuteBonusBalance?: number;
    parachuteBonusPhase?: boolean;
    status?: UserBonusStatus;
    timestamp?: Date;
    totalBonusAmount?: number;
    turnoverRequirement?: number;
    userId?: number;
}

export interface UserDeposit {
    formSteps?: FormStep[];
    verification?: UserVerificationRequirements;
}

export interface UserGameReward {
    amount?: number;
    claimTime?: Date;
    created?: Date;
    currencyCode?: string;
    expires?: Date;
    gameId?: number;
    id?: number;
    integration?: GameIntegration;
    messageRead?: boolean;
    nrOfFreeSpins?: number;
    providerReference?: string;
    providerResponse?: ProviderRewardResponse;
    removed?: boolean;
    rewardTemplate?: RewardTemplate;
    status?: RewardStatus;
    userId?: number;
}

export interface UserGameRewardQueryResult extends ListResponse {
    items?: UserGameReward[];
    queryLimit?: number;
    queryOffset?: number;
}

export interface UserInfo extends Serializable {
    attributes?: { [index: string]: string };
    avatarUrl?: string;
    blocked?: boolean;
    blockedStatus?: BlockedStatus;
    country?: string;
    currencies?: Currency[];
    deviceToken?: string;
    email?: string;
    emailVerified?: boolean;
    errorMessage?: string;
    internalUserId?: number;
    kycStatus?: KycStatus;
    kycVerified?: boolean;
    locale?: string;
    loggedIn?: boolean;
    manualApprovalRequired?: boolean;
    mfaProvider?: MultiFactorAuthProvider;
    mfaStatus?: MultiFactorAuthStatus;
    newUser?: boolean;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    principalVerified?: boolean;
    responseContext?: string;
    responseStatus?: LoginResponseStatus;
    secret?: string;
    token?: string;
    tokenRefId?: number;
    userSettings?: { [index: string]: string };
    userUid?: string;
    username?: string;
    verificationChannel?: LoginVerification;
}

export interface UserInput {
    field?: UserPrimaryField;
    hidden?: boolean;
    minAge?: number;
    repeatInput?: boolean;
    required?: boolean;
    type?: InputType;
    userAttributeKey?: string;
}

export interface UserInputGroup {
    inputs?: UserInput[];
    name?: string;
    translationKey?: string;
}

export interface UserJourney {
    deposit?: UserDeposit;
    login?: UserLogin;
    registration?: UserRegistration;
    userLoginPrincipals?: UserLoginPrincipal[];
    withdraw?: UserWithdraw;
}

export interface UserJourneyAndAttributes {
    countries?: Country[];
    journey?: UserJourney;
    userAttributes?: { [index: string]: UserAttributeDefinition };
}

export interface UserLevelView {
    currentLevel?: number;
    currentLevelName?: string;
    nextLevelName?: string;
    nextLevelProgressionPercentage?: number;
}

export interface UserLogin {
    verifications?: UserVerificationRequirements;
}

export interface UserMessage {
    created?: Date;
    delivered?: boolean;
    deliveredTimestamp?: Date;
    hideToast?: boolean;
    id?: number;
    imageUrl?: string;
    message?: string;
    meta?: UserMessageMeta;
    read?: boolean;
    title?: string;
    userId?: number;
}

export interface UserMessageMeta {
    category?: UserMessageCategory;
    data?: { [index: string]: string };
    type?: UserMessageType;
}

export interface UserRegistration {
    exclude?: UserLoginPrincipal[];
    formSteps?: FormStep[];
    riskCollectionId?: number;
    verification?: UserVerificationRequirements;
}

export interface UserVerificationRequirements {
    kyc?: boolean;
}

export interface UserWithdraw {
    formSteps?: FormStep[];
    verification?: UserVerificationRequirements;
}

export interface VoucherRedemptionResult {
    amount?: number;
    code?: VoucherRedemptionResultCode;
    currency?: string;
}

export interface WidgetAuthRequest {
    requestDate?: string;
    userId?: number;
}

export interface WidgetAuthResponse {
    loginToken?: string;
    userId?: number;
}

export interface WidgetAuthentication {
    authenticationRequired?: boolean;
    error?: boolean;
    token?: string;
    variableName?: string;
}

export interface WithdrawContext {
    bankTransferContext?: BankTransferContext;
    cryptoContext?: CryptoWithdrawContext;
    customContext?: any;
    inputOrders?: { [index: string]: string[] };
    inputs?: PaymentInput[];
    kycRequired?: boolean;
    maxWithdraw?: number;
    minWithdraw?: number;
    permitted?: WithdrawPermitted;
    preReserveFunds?: boolean;
    providerProperties?: { [index: string]: string };
}

export interface XenditWithdrawContext {
    input?: PaymentInput[];
}

export interface XhtmlLink {
    href?: string;
    hreflang?: string;
    rel?: string;
}

export enum AccountRole {
    MAIN = "MAIN",
    RAKE = "RAKE",
    WALLET = "WALLET",
    BONUS = "BONUS",
    PROMOTION = "PROMOTION",
}

export enum AppContext {
    MAYAMINIAPP = "MAYAMINIAPP",
    GLIFE = "GLIFE",
}

export enum AppMode {
    NONE = "NONE",
    PWA = "PWA",
    NATIVE = "NATIVE",
    EMBEDDED = "EMBEDDED",
}

export enum AttributeType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    SYSTEM = "SYSTEM",
    SYSTEM_INTERNAL = "SYSTEM_INTERNAL",
}

export enum AwardType {
    CLAIMED = "CLAIMED",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
}

export enum BlockedStatus {
    NONE = "NONE",
    BLOCKED_BY_ADMIN = "BLOCKED_BY_ADMIN",
    SELF_EXCLUSION = "SELF_EXCLUSION",
    BLOCKED_COUNTRY = "BLOCKED_COUNTRY",
}

export enum BonusLogicType {
    PARACHUTE = "PARACHUTE",
    BOUNTY = "BOUNTY",
}

export enum BonusOfferStatus {
    OPEN = "OPEN",
    EXPIRED = "EXPIRED",
    CANCELED = "CANCELED",
    CLAIMED = "CLAIMED",
    BLOCKED = "BLOCKED",
}

export enum BonusPaymentType {
    DIRECT = "DIRECT",
    CASH_REWARD = "CASH_REWARD",
}

export enum BonusStatus {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
    ARCHIVED = "ARCHIVED",
}

export enum BonusStatusAlert {
    GREY = "GREY",
    RED = "RED",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
}

export enum BundleType {
    purchase = "purchase",
    signOnBonus = "signOnBonus",
}

export enum CasinoGameAspectRatio {
    R_16_9 = "R_16_9",
    R_4_3 = "R_4_3",
    R_3_4 = "R_3_4",
    R_9_16 = "R_9_16",
}

export enum CasinoGameType {
    ARCADE_GAMES = "ARCADE_GAMES",
    SLOTS = "SLOTS",
    LIVE_CASINO = "LIVE_CASINO",
    TABLE_GAMES = "TABLE_GAMES",
    VIDEO_POKER = "VIDEO_POKER",
    OTHER = "OTHER",
    VIRTUAL_SPORTS = "VIRTUAL_SPORTS",
    BINGO = "BINGO",
}

export enum CasinoLobbyCategoryType {
    GAME_SELECTION = "GAME_SELECTION",
    CONTENT = "CONTENT",
    STUDIO_SELECTION = "STUDIO_SELECTION",
}

export enum CasinoMobileNavigation {
    INHERIT = "INHERIT",
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
}

export enum ContributionProduct {
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    POKER = "POKER",
    RACING = "RACING",
}

export enum DepositResultCode {
    OK = "OK",
    DECLINED = "DECLINED",
    PENDING_VERIFICATION = "PENDING_VERIFICATION",
    ERROR = "ERROR",
    NOT_SUPPORTED = "NOT_SUPPORTED",
    OVER_LIMIT = "OVER_LIMIT",
    ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS",
}

export enum GameDisplay {
    FIXED = "FIXED",
    SCALE_OUT = "SCALE_OUT",
    EMBEDDED = "EMBEDDED",
}

/**
 * Values:
 * - `ALL`
 * - `INTERNAL`
 * - `PAYMENTS`
 * - `CUBEIA`
 * - `SONG88`
 * - `BOOONGO`
 * - `LATROBET`
 * - `SA_GAMING`
 * - `NUCLEUS`
 * - `ULTRAPLAY`
 * - `QTECH`
 * - `SPRIBE`
 * - `HUB88`
 * - `ZITRO`
 * - `SABA`
 * - `IMOON`
 * - `FLOW_GAMING`
 * - `PARIPLAY`
 * - `CHERRY`
 * - `REFERENCE_PROVIDER`
 * - `JILI`
 * - `SLOTEGRATOR`
 * - `ST8`
 * - `BIGPOT`
 * - `BETBY`
 * - `EVOLUTION`
 * - `PRAGMATIC_PLAY`
 * - `FA_CHAI`
 * - `MAYAMINIAPP` - @deprecated
 * - `BLINK`
 */
export enum GameIntegration {
    ALL = "ALL",
    INTERNAL = "INTERNAL",
    PAYMENTS = "PAYMENTS",
    CUBEIA = "CUBEIA",
    SONG88 = "SONG88",
    BOOONGO = "BOOONGO",
    LATROBET = "LATROBET",
    SA_GAMING = "SA_GAMING",
    NUCLEUS = "NUCLEUS",
    ULTRAPLAY = "ULTRAPLAY",
    QTECH = "QTECH",
    SPRIBE = "SPRIBE",
    HUB88 = "HUB88",
    ZITRO = "ZITRO",
    SABA = "SABA",
    IMOON = "IMOON",
    FLOW_GAMING = "FLOW_GAMING",
    PARIPLAY = "PARIPLAY",
    CHERRY = "CHERRY",
    REFERENCE_PROVIDER = "REFERENCE_PROVIDER",
    JILI = "JILI",
    SLOTEGRATOR = "SLOTEGRATOR",
    ST8 = "ST8",
    BIGPOT = "BIGPOT",
    BETBY = "BETBY",
    EVOLUTION = "EVOLUTION",
    PRAGMATIC_PLAY = "PRAGMATIC_PLAY",
    FA_CHAI = "FA_CHAI",
    /**
     * @deprecated
     */
    MAYAMINIAPP = "MAYAMINIAPP",
    BLINK = "BLINK",
}

export enum GameOrder {
    RELEASE_DATE = "RELEASE_DATE",
    POPULARITY = "POPULARITY",
    ALPHABETICAL = "ALPHABETICAL",
}

export enum InputType {
    LOGIN_PRINCIPAL = "LOGIN_PRINCIPAL",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    PASSWORD = "PASSWORD",
    ACCEPT_TERMS = "ACCEPT_TERMS",
    AUTO_ACCEPT_TERMS = "AUTO_ACCEPT_TERMS",
    COUNTRY = "COUNTRY",
    TEXT = "TEXT",
    DATE_OF_BIRTH = "DATE_OF_BIRTH",
    DATE = "DATE",
    CHECKBOX = "CHECKBOX",
    CHECKBOX_CHECKED = "CHECKBOX_CHECKED",
    PROMO_CODE = "PROMO_CODE",
}

export enum InternalUserAttributes {
    remoteAddress = "remoteAddress",
    platform = "platform",
    tracker = "tracker",
    campaign = "campaign",
    marketingChannel = "marketingChannel",
    locale = "locale",
    screenName = "screenName",
    signUpBonusBundleClaimed = "signUpBonusBundleClaimed",
    firstName = "firstName",
    lastName = "lastName",
    geoipLoginCountry = "geoipLoginCountry",
    geoipLoginCountryCode = "geoipLoginCountryCode",
    geoipLoginRegion = "geoipLoginRegion",
    geoipLoginRegionCode = "geoipLoginRegionCode",
    geoipRegistrationCountry = "geoipRegistrationCountry",
    geoipRegistrationCountryCode = "geoipRegistrationCountryCode",
    geoipRegistrationRegion = "geoipRegistrationRegion",
    geoipRegistrationRegionCode = "geoipRegistrationRegionCode",
    geoipRegistrationCity = "geoipRegistrationCity",
    geoipLoginMapLink = "geoipLoginMapLink",
    agentId = "agentId",
    agentName = "agentName",
    agentTop = "agentTop",
    disableCountryBlock = "disableCountryBlock",
    bankName = "bankName",
    bankAccountNumber = "bankAccountNumber",
    bankAccountOwner = "bankAccountOwner",
    bankRoutingNumber = "bankRoutingNumber",
    preferredCurrency = "preferredCurrency",
    source = "source",
    preferredFiatExchangeCurrency = "preferredFiatExchangeCurrency",
    preferredCashbackCurrency = "preferredCashbackCurrency",
    termsVersionAccepted = "termsVersionAccepted",
    displayFiatExchangeIfAvailable = "displayFiatExchangeIfAvailable",
    country = "country",
    dateOfBirth = "dateOfBirth",
    affiliateTracker = "affiliateTracker",
    promoCode = "promoCode",
    addressLine1 = "addressLine1",
    addressLine2 = "addressLine2",
    city = "city",
    zipCode = "zipCode",
    gender = "gender",
    skipDeviceTokenCheck = "skipDeviceTokenCheck",
    receivePromoCommunication = "receivePromoCommunication",
    clickId = "clickId",
    deviceIdInitial = "deviceIdInitial",
    deviceId = "deviceId",
    usedAppMode = "usedAppMode",
    preferredPokerExchangeCurrency = "preferredPokerExchangeCurrency",
    agentServicePlayerUsername = "agentServicePlayerUsername",
    btag = "btag",
    registrationRiskResults = "registrationRiskResults",
    domain = "domain",
    siteNameId = "siteNameId",
    fingerprint = "fingerprint",
    fingerprintInitial = "fingerprintInitial",
    fpUserAgent = "fpUserAgent",
    mayaMiniAppId = "mayaMiniAppId",
    appContext = "appContext",
    gLifeUserId = "gLifeUserId",
}

export enum JurisdictionName {
    NONE = "NONE",
    UNREGULATED_EUROPE = "UNREGULATED_EUROPE",
    PAGCOR = "PAGCOR",
    UNREGULATED_ASIA = "UNREGULATED_ASIA",
}

export enum KycStatus {
    NOT_STARTED = "NOT_STARTED",
    PENDING = "PENDING",
    REVIEW_NEEDED = "REVIEW_NEEDED",
    REJECTED = "REJECTED",
    VERIFIED = "VERIFIED",
}

export enum LoginResponseStatus {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
    VERIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
    FAILED = "FAILED",
    BLOCKED = "BLOCKED",
    SELF_EXCLUDED = "SELF_EXCLUDED",
    FB_EMAIL_ALREADY_EXIST = "FB_EMAIL_ALREADY_EXIST",
    THIRD_PARTY_SSO_USER_EXIST = "THIRD_PARTY_SSO_USER_EXIST",
    BLOCKED_COUNTRY = "BLOCKED_COUNTRY",
    MULTI_FACTOR_AUTH_REQUIRED = "MULTI_FACTOR_AUTH_REQUIRED",
    MULTI_FACTOR_AUTH_FAILED = "MULTI_FACTOR_AUTH_FAILED",
    ACCEPT_TERMS_REQUIRED = "ACCEPT_TERMS_REQUIRED",
    UNDER_AGE = "UNDER_AGE",
    MANUAL_APPROVAL_REQUIRED = "MANUAL_APPROVAL_REQUIRED",
    LOGIN_DISABLED = "LOGIN_DISABLED",
}

export enum LoginVerification {
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    USERNAME = "USERNAME",
    TOKEN = "TOKEN",
    OAUTH = "OAUTH",
}

export enum MaintenanceCategory {
    ALL = "ALL",
    SPORTSBOOK = "SPORTSBOOK",
    CASINO = "CASINO",
    POKER = "POKER",
    RACING = "RACING",
}

export enum MenuComponentType {
    LOGIN_REGISTER = "LOGIN_REGISTER",
    USER_LEVEL = "USER_LEVEL",
    CASHBACK = "CASHBACK",
    NONE = "NONE",
}

export enum MenuItemType {
    POKER = "POKER",
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    RACING = "RACING",
    CUSTOM = "CUSTOM",
}

export enum MenuPosition {
    FIXED = "FIXED",
    ABSOLUTE = "ABSOLUTE",
}

export enum MultiFactorAuthProvider {
    OTP = "OTP",
    EMAIL = "EMAIL",
    SMS = "SMS",
}

export enum MultiFactorAuthStatus {
    DISABLED = "DISABLED",
    PENDING = "PENDING",
    ENABLED = "ENABLED",
}

export enum NotEligibleReason {
    NONE = "NONE",
    NO_AWARD_RATE = "NO_AWARD_RATE",
}

export enum PaymentInputType {
    TEXT = "TEXT",
    PHONE_NUMBER = "PHONE_NUMBER",
    EMAIL = "EMAIL",
    SELECT = "SELECT",
}

export enum PaymentLogEventType {
    INITIATED = "INITIATED",
    CALLBACK_RECEIVED = "CALLBACK_RECEIVED",
    VERIFICATION_FAILED = "VERIFICATION_FAILED",
    DECLINED = "DECLINED",
    INTERNAL_TRANSFER_COMPLETE = "INTERNAL_TRANSFER_COMPLETE",
    INTERNAL_TRANSFER_FAILED = "INTERNAL_TRANSFER_FAILED",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
    COMPLETED = "COMPLETED",
    RESOLVED = "RESOLVED",
    GENERIC = "GENERIC",
}

export enum PaymentPermitted {
    YES = "YES",
    NO = "NO",
    NO_WRONG_CURRENCY = "NO_WRONG_CURRENCY",
    NO_GENERAL_ERROR = "NO_GENERAL_ERROR",
    NO_BLOCKED = "NO_BLOCKED",
    NO_USER_NOT_FOUND = "NO_USER_NOT_FOUND",
    NO_LIMIT = "NO_LIMIT",
}

export enum PaymentResultCode {
    OK = "OK",
    DECLINED = "DECLINED",
    DECLINED_REQUEST_ALREADY_OPEN = "DECLINED_REQUEST_ALREADY_OPEN",
    DECLINED_NO_DEPOSIT_FOUND = "DECLINED_NO_DEPOSIT_FOUND",
    DECLINED_NO_TARGET_ADDRESS_SET = "DECLINED_NO_TARGET_ADDRESS_SET",
    DECLINED_DEPOSITS_WAITING_TO_CLEAR = "DECLINED_DEPOSITS_WAITING_TO_CLEAR",
    DECLINED_AMOUNT_TOO_LOW = "DECLINED_AMOUNT_TOO_LOW",
    DECLINED_BONUS_ACTIVE = "DECLINED_BONUS_ACTIVE",
    DECLINED_KYC_REQUIRED = "DECLINED_KYC_REQUIRED",
    DECLINED_MISSING_AUTH_DATA = "DECLINED_MISSING_AUTH_DATA",
    ERROR = "ERROR",
}

/**
 * Values:
 * - `INITIATED`
 * - `PENDING`
 * - `FAILED`
 * - `FAILED_PERMANENTLY`
 * - `COMPLETED`
 * - `DECLINED` - @deprecated
 * - `CANCELED`
 * - `RESOLVED`
 * - `PLACEHOLDER`
 * - `APPLIED_WAITING_TO_CLEAR` - @deprecated
 */
export enum PaymentStatus {
    INITIATED = "INITIATED",
    PENDING = "PENDING",
    FAILED = "FAILED",
    FAILED_PERMANENTLY = "FAILED_PERMANENTLY",
    COMPLETED = "COMPLETED",
    /**
     * @deprecated
     */
    DECLINED = "DECLINED",
    CANCELED = "CANCELED",
    RESOLVED = "RESOLVED",
    PLACEHOLDER = "PLACEHOLDER",
    /**
     * @deprecated
     */
    APPLIED_WAITING_TO_CLEAR = "APPLIED_WAITING_TO_CLEAR",
}

export enum PaymentType {
    DEPOSIT = "DEPOSIT",
    WITHDRAWAL = "WITHDRAWAL",
    AUTH = "AUTH",
    BONUS = "BONUS",
    CORRECTION = "CORRECTION",
}

export enum Priority {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
}

export enum Product {
    POKER = "POKER",
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    PAYMENT = "PAYMENT",
    RACING = "RACING",
    MAYAMINIAPP = "MAYAMINIAPP",
    GLIFE = "GLIFE",
}

export enum ReCaptchaAction {
    login = "login",
    register = "register",
    recoverPassword = "recoverPassword",
    requestPrincipalVerification = "requestPrincipalVerification",
}

export enum RewardStatus {
    NEW = "NEW",
    CLAIMED = "CLAIMED",
}

export enum RewardType {
    freespins = "freespins",
    cash = "cash",
    freebet = "freebet",
}

export enum RuleTriggerType {
    ABSOLUTE_COUNT = "ABSOLUTE_COUNT",
    EVERY_TIME = "EVERY_TIME",
    MANUAL = "MANUAL",
}

export enum SabaPlatform {
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE",
}

export enum SportsbookProvider {
    SONG88 = "SONG88",
    ULTRAPLAY = "ULTRAPLAY",
    SABA = "SABA",
    BETBY = "BETBY",
}

export enum Status {
    SUCCESS = "SUCCESS",
    SCREEN_NAME_EXIST = "SCREEN_NAME_EXIST",
    EMAIL_EXIST = "EMAIL_EXIST",
    PHONE_EXIST = "PHONE_EXIST",
    INVALID_DATA = "INVALID_DATA",
    INVALID_CHARACTER = "INVALID_CHARACTER",
    BLOCKED_DOMAIN = "BLOCKED_DOMAIN",
    BLOCKED_IP = "BLOCKED_IP",
    BLOCKED_COUNTRY = "BLOCKED_COUNTRY",
    PHONE_INVALID_FORMAT = "PHONE_INVALID_FORMAT",
    UNDER_AGE = "UNDER_AGE",
    DENIED = "DENIED",
    INVALID_PROMO_CODE = "INVALID_PROMO_CODE",
}

export enum Style {
    main = "main",
    alternative = "alternative",
    promo = "promo",
    lite = "lite",
}

export enum UserBonusStatus {
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
    BUSTED = "BUSTED",
}

export enum UserLoginPrincipal {
    EMAIL = "EMAIL",
    PHONE_NUMBER = "PHONE_NUMBER",
}

export enum UserMessageCategory {
    GENERIC = "GENERIC",
    REWARD = "REWARD",
    BONUS = "BONUS",
    PAYMENT = "PAYMENT",
    GAME = "GAME",
}

export enum UserMessageType {
    GENERIC = "GENERIC",
    REWARD_RECEIVED = "REWARD_RECEIVED",
    WITHDRAW_DENIED = "WITHDRAW_DENIED",
    WITHDRAW_APPROVED = "WITHDRAW_APPROVED",
    WITHDRAW_CONFIRMATION_CODE = "WITHDRAW_CONFIRMATION_CODE",
    WITHDRAW_FAILED = "WITHDRAW_FAILED",
    BONUS_OFFERED = "BONUS_OFFERED",
    BONUS_ENDED = "BONUS_ENDED",
    BONUS_TURNOVER_COMPLETED = "BONUS_TURNOVER_COMPLETED",
    BONUS_PAID_OUT = "BONUS_PAID_OUT",
    DEPOSIT_COMPLETE = "DEPOSIT_COMPLETE",
    BET_WON = "BET_WON",
    BET_LOST = "BET_LOST",
    BET_REFUNDED = "BET_REFUNDED",
    BET_LIMIT_EXCEEDED = "BET_LIMIT_EXCEEDED",
    WIN_LIMIT_EXCEEDED = "WIN_LIMIT_EXCEEDED",
    BET_REJECTED = "BET_REJECTED",
    LEVEL_UP = "LEVEL_UP",
    TIP_RECEIVED = "TIP_RECEIVED",
    TESTING_MESSAGES = "TESTING_MESSAGES",
}

export enum UserPrimaryField {
    loginPrincipal = "loginPrincipal",
    email = "email",
    phoneNumber = "phoneNumber",
    password = "password",
    termsAccepted = "termsAccepted",
}

export enum UserWriteAccess {
    NONE = "NONE",
    CREATE = "CREATE",
    CREATE_AND_UPDATE = "CREATE_AND_UPDATE",
}

export enum VoucherRedemptionResultCode {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    FAILURE_NOT_OPEN = "FAILURE_NOT_OPEN",
    FALIURE_WRONG_CODE = "FALIURE_WRONG_CODE",
    FAILURE_WRONG_USER = "FAILURE_WRONG_USER",
    FAILURE_ALREADY_REDEEMED = "FAILURE_ALREADY_REDEEMED",
    FAILURE_REVOKED = "FAILURE_REVOKED",
    FAILURE_EXPIRED = "FAILURE_EXPIRED",
}

export enum WithdrawPermitted {
    YES = "YES",
    REJECTED_NO_PREVIOUS_DEPOSIT_FOUND = "REJECTED_NO_PREVIOUS_DEPOSIT_FOUND",
    DEPOSITS_WAITING_TO_CLEAR = "DEPOSITS_WAITING_TO_CLEAR",
    NOT_SUPPORTED = "NOT_SUPPORTED",
    AMOUNT_TOO_SMALL = "AMOUNT_TOO_SMALL",
    BONUS_ACTIVE = "BONUS_ACTIVE",
    REQUEST_ALREADY_OPEN = "REQUEST_ALREADY_OPEN",
    KYC_REQUIRED = "KYC_REQUIRED",
    REJECTED_GENERIC = "REJECTED_GENERIC",
}
