import i18n from "i18next"
import Backend from 'i18next-xhr-backend';
import {Locale} from "./contenful/types";
import {SettingsService} from "./settings/SettingsService";
import {func} from "prop-types";


const LOCALE_REGEX = "^([A-Za-z]{2})([_-]([A-Za-z]{4}))?([_-]([A-Za-z]{2}))?$";

/**
 * Get the translation for the given key and apply parameters to it.
 * @param key key (will be converted to lowercase)
 * @param params parameters to key (optional)
 * @param defaultValue default value in case key is not found (optional)
 * @returns 
 */
export function t(key : string, params? : any, defaultValue?: string | undefined) {
    key = key.toLowerCase()

    const t = i18n.t(key, params);

    if (defaultValue !== undefined  &&  t === key) {
        return defaultValue
    } 
    
    return t
}

//export to make it easy to import
export function translate(key : string, params? : any) {
    return t(key, params);
}

function findLocale(localesToMatch: string[], locales: Locale[]) {
    let active = undefined;
    for (let i = 0; i < localesToMatch.length; i++) {
        let bl = localesToMatch[i];
        let match = findMatch(bl, locales);
        if (match) {
            active = match;
            break;
        }
    }
    return active;
}
function isCrawler() {
    return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)
}
export function getLocaleFromPath() : string | undefined {
    let path = document.location.pathname;
    let parts = path.split("/");
    if(parts.length > 1) {
        if(parts[1].match(LOCALE_REGEX)) {
            return parts[1];
        }
    }
}
export function getTargetLanguagePath(localeFromPath : string, targetLocale : string) {
    let path = document.location.pathname;
    const search = document.location.search
    if (path.startsWith("/")) {
        path = path.substring(1);
    }
    path = path.substring(localeFromPath.length);

    if (!path.startsWith("/")) {
        path = "/" + path;
    }
    path = "/" + targetLocale + path + search
    return path;
}
export function changeLocaleDirectory(targetLocale: string) {
    let current = getLocaleFromPath();
    if(current) {
        document.location.href = getTargetLanguagePath(current, targetLocale);

    }
}
export function getActiveLocale(settings : SettingsService, localeFromPath? : string, cookieLocale?: string) {
    let locales : Locale[] = settings.getLocalesData();
    let selectedLocale = undefined;


    if(!selectedLocale && localeFromPath) {
        selectedLocale = findLocale([localeFromPath], locales)
    }

    if(!selectedLocale && cookieLocale && cookieLocale.length > 1) {
        selectedLocale = findLocale([cookieLocale], locales)
    }

    if(!selectedLocale) {
        let browserLocales : string[] = getBrowserLocales();
        selectedLocale = findLocale(browserLocales, locales);

    }
    
    if(!selectedLocale) {
        selectedLocale = settings.getDefaultLocale();
    }
    return selectedLocale;
}
export async function setupI18n(baseUrl :string, activeLanguage : string, settings : SettingsService)  {

    try {
        await i18n
            .use(Backend)
            //.use(LngDetector)
            .init({
                debug: true,
                lng : activeLanguage,
                fallbackLng: settings.getDefaultLocale().code,
                backend: {
                    crossDomain: true,
                    loadPath: baseUrl + '/translations/{{lng}}',
                }
        });
    } catch (e) {
        console.error("Unable to initialize i18n", e)
    }
}
function findMatch(locale : string, availableLocales : Locale[]):  Locale | undefined {

    let exactMatch = availableLocales.find( l => l.code == locale);
    if(!exactMatch) {
        let index = locale.lastIndexOf("-");
        if(index != -1) {
            return findMatch(locale.substr(0, index), availableLocales);
        }
    } else {
        return exactMatch;
    }

}
export function getBrowserLocales() : string[]{
    let found : string[] = [];
    if (typeof navigator !== 'undefined') {
        if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
            for (let i=0; i < navigator.languages.length; i++) {
                found.push(navigator.languages[i]);
            }
        }
        if ((navigator as any).userLanguage) {
            found.push((navigator as any).userLanguage);
        }
        if (navigator.language) {
            found.push(navigator.language);
        }
    }
    return found;
}

export function getCountryByLocale(locale : string) : string | undefined{
   let parts = locale.match(LOCALE_REGEX);

   if(parts && parts.length == 6) {
       let country = parts[5];
       if(!country){
           return getCountryByLanguage(parts[1]);
       } else {
           return country;
       }
   }

}
const languageMap : { [k in string] : string } = {
    "en" : "US",
    "sv" : "SE",
    "th" : "TH",
    "zh" : "CN",
    "da" : "DK",
    "fi" : "FI",
    "pt" : "PT",
    "es" : "ES",
    "no" : "NO",
    "fr" : "FR",
    "de" : "DE",
    "pl" : "PL",
    "ko" : "KR",
    "hi" : "IN",
    "uk" : "UA",
    "hy" : "AM",
    "is" : "IS",
    "ru" : "RU",
    "kk" : "KZ",
    "ja" : "JP"



};
export function getCountryByLanguage(lng: string) {
    return languageMap[lng.toLocaleLowerCase()];
}