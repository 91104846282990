import {Locale} from "./contenful/types";

export interface LocaleStatus {
    localeFromPath? : string;
    activeLocale: Locale;
}
export const ACTIVE_LOCALE_COOKIE  = "activeLocale";
export function addStylesheet(href : string, onload? : () => void, onerror? : () => void) {
    let css: HTMLLinkElement = document.createElement("link");
    css.href = href;
    css.rel = "stylesheet";
    css.media = "all";
    if(onload) {
        css.onload = () => {
            onload();
        };
    }
    if(onerror) {
        css.onerror = () =>  {
            onerror();
        }
    }
    try {
        if(css.href.length > 0) {}
        document.body.appendChild(css);
    } catch (e) {

    }
}

export function addScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.src = url.trim();
        document.body.appendChild(script);
        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            reject(new Error(`Failed to load script at url: ${url}`));
        };
    });
}
