import axios, {AxiosInstance} from "axios";

export interface StrapiLocale {
    id: number;
    name: string;
    code: string;
    createdAt: string;
    isDefault : boolean;
}

export default class StrapiClient {
    axios : AxiosInstance;
    baseUrl : string | undefined;

    constructor(strapiBaseUrl? : string) {
        let urlOverride = localStorage.getItem("strapiApiUrlOverride");
        if(urlOverride){
            this.baseUrl = urlOverride;
        } else {
            this.baseUrl = strapiBaseUrl;
        }
        this.axios = axios.create({
            headers : {
                "Content-type" : "application/json"
            }
        })
    }

    isEnabled() {
        return this.baseUrl && this.baseUrl != "";
    }

    fetchContent(path : string, locale : string) {
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + "/" + path + "?populate=deep&locale="+locale)
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => reject(e))
        })
    }

   
    fetchArticlesContent(path : string, locale : string) {
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + "/" + path + "?populate=deep&locale="+locale+"&pagination[pageSize]=250&sort=date:DESC")
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => reject(e))
        })
    }

    fetchLocales(): Promise<StrapiLocale[]> {
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + "/i18n/locales")
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => reject(e))
        })
    }
    fetchContentByNameId(path : string, nameId : string, locale : string)   {
        return  new Promise((resolve,reject)=> {
            this.axios.get(`${this.baseUrl}/${path}?populate=deep&filters[nameId][$eq]=${nameId}&locale=${locale}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => reject(e))
        })
    }
    fetchCasinoPages(locale : string) {
        return this.fetchContent("/casino-pages", locale);
    }
}
