import axios, {AxiosInstance} from "axios";


export class HttpService {
    axios : AxiosInstance;
    lastRequest :number;
    lastSuccessfulRequest : number;
    constructor(private baseUrl : string) {
        this.lastRequest = Date.now();
        this.lastSuccessfulRequest = Date.now();
        this.axios = axios.create({
            headers : {
                "Content-type" : "application/json"
            }
        })
    }
    getTimeSinceLastRequest() : number {
        return Date.now() - this.lastRequest;
    }
    getTimeSinceLastSuccessfulRequest() : number {
        return Date.now() - this.lastSuccessfulRequest;
    }

    get<R>(url : string, headers?: any) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url, { withCredentials : true, headers : headers })
                .then(response => {
                    this.lastSuccessfulRequest = Date.now();
                    resolve(response.data)
                })
                .catch(e => this.handleError(url,e,reject))
        })
    }
    getWithSite<R>(url : string, headers?: any) : Promise<{ response : R, siteNameId : string}> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url, { withCredentials : true, headers : headers })
                .then(response => {
                    this.lastSuccessfulRequest = Date.now();
                    resolve({ response : response.data, siteNameId : response?.headers?.["x-site-name-id"] ?? "default"});
                })
                .catch(e => this.handleError(url,e,reject))
        })
    }
    post<R,E>(url: string, entity : E, headers? : any) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + url,entity, { withCredentials : true, headers : headers})
                .then(response => {
                    this.lastSuccessfulRequest = Date.now();
                    resolve(response.data)
                })
                .catch(e => this.handleError(url,e,reject))
        })
    }
    put<R>(url : string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.put(this.baseUrl + url, {},{ withCredentials : true })
                .then(response => {
                    this.lastSuccessfulRequest = Date.now();
                    resolve(response.data)
                })
                .catch(e => this.handleError(url,e,reject))
        })
    }
    delete<R>(url:string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.delete(this.baseUrl + url, { withCredentials : true })
                .then(response => {
                    this.lastSuccessfulRequest = Date.now();
                    resolve(response.data)
                })
                .catch(e => this.handleError(url,e,reject))
        })
    }
    shouldRedirectToAppLogin( url : string) {
        const token = localStorage.getItem("token");
        const salt = localStorage.getItem("salt");
        if(!token || !salt) {
            return false;
        }
        if(url.indexOf("/user/authenticate") != -1 || url.indexOf("/user/logout") != -1) {
            return false;
        }
        return true;
    }
    handleError(url : string, e : any, reject : any) {
        if(e.response && e.response.status == 401 && this.shouldRedirectToAppLogin(url)) {
            document.location.pathname = "/app";
        } else if (e.response?.status == 401 && !url.endsWith("/user/account-balances-and-bonuses")) {
            document.location.reload();
        } else {
            reject(e);
        }
    }
}